import React, { Component } from 'react';


import Grid from '@material-ui/core/Grid';


import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import ConfigureStyle from './Configure.style';


class IntentOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Options: [],
    };
    this.onOptionChecked = this.onOptionChecked.bind(this);
  }
  onOptionChecked(e) {
    const { name, checked } = e.target;
    const unprefixedName = name.replace('intent-', '').replace('option-', '');
    // If this is our first time overriding intents, set the local intent list
    const { Options, onChange } = this.props;
    let OptionsSelected = this.state.Options;

    if (OptionsSelected.length === 0) {
      OptionsSelected = Options.map(i => i.Alias || i.Value || i.Label);
    }
    if (checked) {
      OptionsSelected.push(unprefixedName);
    } else {
      OptionsSelected = OptionsSelected.filter(i => i !== unprefixedName);
    }

    if (OptionsSelected.length === 0) {
      onChange({ target: { name, checked, value: OptionsSelected } });
    }

    this.setState({ Options: OptionsSelected }, () => {
      onChange({ target: { name, checked, value: OptionsSelected } });
    });
  }
  render() {
    const { Options, classes } = this.props;

    const optionsGrid = Options.map((option, idx) => {
      let isOptionChecked = false;
      const label = option.Alias || option.Label;
      const value = option.Value || option.Alias || option.Label;
      if (this.state.Options.length === 0 || this.state.Options.indexOf(value) > -1) {
        isOptionChecked = true;
      }
      const prefix = (option.Alias) ? 'intent-' : 'option-';
      return (
        <Grid item xs={4} key={`IntentOption${label}${idx}`}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{ root: classes.checkbox, checked: classes.checked }}
                name={`${prefix}${value}`}
                checked={isOptionChecked}
                onChange={this.onOptionChecked} />}
            label={label}
          />
        </Grid>
      );
    });

    return optionsGrid;
  }
}

export default withStyles(ConfigureStyle)(IntentOptions);
