import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import GridCardHeader from './GridCardHeader';
import IntentOptions from './IntentOptions';
import colors from './colors';

const { bubbleBlue, white } = colors;

const styles = {
  optionsWrapper: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    margin: '0 30px 30px 65px',
    width: '100%',
  },
  description: {
    marginLeft: 60,
  },
  root: {},
  cssFocused: {},
  textField: {
    '& div, & input, & textarea': {
      backgroundColor: white,
      '&:hover, &:active, &:focus': {
        backgroundColor: white,
      },
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: bubbleBlue,
    },
  },
  cssUnderline: {
    '&:after': {
      borderBottom: `2px solid ${bubbleBlue}`,
    },
  },
  textfieldsWrapper: {
    marginLeft: -8,
  },
};

class IntentSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enabled: false,
    };
    this.onSwitch = this.onSwitch.bind(this);
    this.onOptionChecked = this.onOptionChecked.bind(this);
  }
  onSwitch(e) {
    let { Enabled } = this.state;
    Enabled = !Enabled;
    this.setState({ Enabled });
    this.props.onChange(e);
  }
  onOptionChecked(e) {
    const checked = e.target.value;
    if (checked.length === 0) {
      this.setState({ Enabled: false });
    }
    this.props.onChange(e);
  }
  render() {
    const {
      DisplayName, Description, Parameters, onChange, type, classes,
    } = this.props;
    let { Intents } = this.props;
    const { Enabled } = this.state;
    Intents = Intents || [];

    const intentParams = type !== 'pipeline' && Parameters && Parameters.map((param, idx) => {
      const { Label, Placeholder, Type } = param;
      return (
        <TextField
          className={classes.textField}
          key={`BusinessIntentParam${DisplayName}${idx}`}
          name={`param-${Label}`}
          label={Label}
          style={{ margin: 8 }}
          placeholder={Placeholder}
          type={Type}
          fullWidth
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              root: classes.inputField,
              underline: classes.cssUnderline,
            },
          }}
          onChange={onChange}
        />
      );
    });

    return (
      <Grid
        container
        spacing={3}
        alignItems="baseline"
      >
        <Grid item xs={12}>
          <GridCardHeader title={DisplayName} canBeEnabled enabled={Enabled} onSwitch={this.onSwitch} />
          <Typography variant="body1" gutterBottom className={classes.description}>
            {!Enabled && type !== 'pipeline' &&
              <span>
                {Intents.length} Intents
              </span>
            }
            { Description }
          </Typography>
        </Grid>
        <div className={classes.optionsWrapper}>
          {Enabled && Intents &&
            <IntentOptions Options={Intents} onChange={this.onOptionChecked} />
          }
          {Enabled && Parameters &&
            <div className={classes.textfieldsWrapper}>
              { intentParams }
            </div>
          }

          {(Enabled && type === 'pipeline' && Parameters) &&
            <IntentOptions Options={Parameters} onChange={this.onOptionChecked} />
          }
        </div>

      </Grid>

    );
  }
}
export default withStyles(styles)(IntentSet);
