import colors from './colors';

const {
  bubbleBlue, lightGray, green, white, navy,
} = colors;
const whitelabel = (process.env.REACT_APP_WHITELABEL === 'true');
const GridCardHeaderStyle = {
  switchBase: {
    color: lightGray,
    position: 'absolute',
    padding: 9,
    borderRadius: '50%',
    '&$checked': { color: bubbleBlue },
    '&$checked:hover': { backgroundColor: 'transparent' },
    '&$checked + $track': { backgroundColor: bubbleBlue },
  },
  checked: {},
  track: {},
  hover: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    color: white,
    backgroundColor: (whitelabel) ? navy : green,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginBottom: 10,
  },
};

export default GridCardHeaderStyle;
