import colors from './colors';

const { bubbleBlue, gray, green } = colors;
const whitelabel = (process.env.REACT_APP_WHITELABEL === 'true');
const activeSelectorColor = (whitelabel) ? green : bubbleBlue;
const ConfigureStyle = {
  root: {
    color: gray,
    '&$checked': {
      color: activeSelectorColor,
    },
    '&$checked:hover': { backgroundColor: 'transparent' },
    '&$checked + $track': { backgroundColor: activeSelectorColor },
  },
  checked: {},
  track: {},
  hover: {},

  checkbox: {
    '&:hover': {
      backgroundColor: 'rgb(3, 164, 232, 0.2)',
    },
    '&$checked': { color: activeSelectorColor },
    '&$checked:hover': { backgroundColor: 'transparent' },
  },
};

export default ConfigureStyle;
