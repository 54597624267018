import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IntentSet from './IntentSet';
import GridCardHeader from './GridCardHeader';
import MockStore from './MockStore';
import colors from './colors';

const { primary, white } = colors;

const styles = {
  button: {
    backgroundColor: primary,
    color: white,
  },
  buttonIcon: {
    marginLeft: 6,
  },
  description: {
    marginBottom: 30,
    marginTop: 20,
  },
  setWrapper: {
    padding: '20px',
  },
};

class Configure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stepsStore: {
        IntentSets: [],
        BusinessIntentSets: [],
        Pipelines: [],
      },
      IntentSets: [],
      BusinessIntentSets: [],
      Pipelines: [],
      Name: `qs${Date.now()}`,
    };
    this.onIntentChange = this.onIntentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  async componentDidMount() {
    const mocks = this.props && this.props.location.search && this.props.location.search.includes('mock');
    if (mocks) {
      return this.setState({
        isLoading: false,
        stepsStore: MockStore.ListWizardSteps,
        mocks: true,
      });
    }
    this.setState({ isLoading: true });
    const listResponse = await fetch(`${process.env.PUBLIC_URL}/ListWizardSteps`, {
      accept: 'application/json',
    });
    if (listResponse.status !== 200) {
      throw new Error(`Error ${listResponse.status} retrieving wizard steps`);
    }
    const { IntentSets, BusinessIntentSets, Pipelines } = await listResponse.json();
    this.setState({
      isLoading: false,
      stepsStore: {
        IntentSets,
        BusinessIntentSets,
        Pipelines,
      },
    });
  }
  onIntentChange(e, store, intent) {
    const availableStepsStore = { ...this.state.stepsStore };
    let currentIntentSets = this.state[store];
    const { name, value, checked } = e.target;
    if (name === 'enabled' && checked === false) {
      currentIntentSets = currentIntentSets.filter(i => i.Name !== intent.DisplayName);
      return this.setState({ [store]: currentIntentSets });
    }
    let config = currentIntentSets.find(i => i.Name === intent.DisplayName);
    if (!config) {
      config = { Name: intent.DisplayName, Parameters: {}, Intents: intent.Intents };
      currentIntentSets.push(config);
    }
    if (name.startsWith('param-')) {
      const paramLabel = name.replace('param-', '');
      config.Parameters[paramLabel] = value;
    }
    if (name.startsWith('intent-')) {
      const trimmed = name.replace('intent-', '');
      if (!checked) {
        config.Intents = [...config.Intents.filter(_intent => _intent.Alias !== trimmed)];
      } else {
        const selectedIntentSet = availableStepsStore[store].find(_storeType =>_storeType.DisplayName === intent.DisplayName);
        const selectedIntentAlias = selectedIntentSet.Intents.find(_intent => _intent.Alias === trimmed);
        config.Intents.push(selectedIntentAlias);
      }
    }
    if (name.startsWith('option-') && store === 'Pipelines'){
      const booleanMapValue = {};
      value.forEach(v => booleanMapValue[v] = true);
      config.Parameters = booleanMapValue;
    }
    return this.setState({ [store]: currentIntentSets });
  }
  onSubmit() {
    const {
      IntentSets, BusinessIntentSets, Pipelines, Name, stepsStore, mocks,
    } = this.state;
    this.props.history.push({
      pathname: `/create${this.props.location.search}`,
      state: {
        createRequest: {
          IntentSets,
          BusinessIntentSets,
          Pipelines,
          Name,
        },
        stepsStore,
        mocks,
      },
    });
  }
  render() {
    const { classes } = this.props;
    const pipelineElements = this.state.stepsStore.Pipelines.map((pipeline, idx) => (
      <div key={`intentset-${idx}`}>
        <IntentSet {...pipeline} type="pipeline" onChange={(e) => { this.onIntentChange(e, 'Pipelines', pipeline); }} />
      </div>
    ));

    const intentSetElements = this.state.stepsStore.IntentSets.map((intent, idx) => (
      <div key={`intentset-${idx}`}>
        <IntentSet {...intent} type="intent" onChange={(e) => { this.onIntentChange(e, 'IntentSets', intent); }} />
      </div>
    ));

    const businessIntentSetElements = this.state.stepsStore.BusinessIntentSets.map((intent, idx) => (
      <div key={`businessintent-${idx}`}>
        <IntentSet {...intent} type="businessintent" onChange={(e) => { this.onIntentChange(e, 'BusinessIntentSets', intent); }} />
      </div>
    ));

    return (
      <Grid container>
        <Grid item xs={12} className={classes.setWrapper}>
          <GridCardHeader title="Pipelines" icon="device_hub" />
          <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
            Pipelines modify messages on their way into & out of the system. They are simple transformations you can apply to messages to make your bot more powerful.
          </Typography>
          <div>
            { pipelineElements }
            <Divider />
            <br />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.setWrapper}>
          <GridCardHeader title="Intents" icon="comment" />
          <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
            Below is a list of sample intents which can bootstrap your bot with common conversational items common to every business.
          </Typography>
          <div>
            { intentSetElements }
            <Divider />
            <br />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.setWrapper}>
          <GridCardHeader title="Business Intents" icon="work" />
          <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
            Business Intents combine traditional NLP with the language of backend systems to enable business processess through conversation.
          </Typography>
          <div>
            { businessIntentSetElements }
            <Divider />
            <br />
          </div>
          <Grid container justify="center">
            <Grid item>
              <Button className={classes.button} disableElevation variant="contained" onClick={this.onSubmit}>
                Next <ArrowForward className={classes.buttonIcon} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(Configure));
