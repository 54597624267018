import React from 'react';

export default ({ className, fill, backgroundColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 26" className={className}>
    <g fill="none">
      <ellipse cx="7.72" cy="12.7" fill="#3392CF" rx="1.03" ry="1.16" />
      <ellipse cx="11.83" cy="12.7" fill="#3392CF" rx="1.03" ry="1.16" />
      <path fill={fill || '#4A4A4A'} d="M10.12 5.37c-4.17 0-7.54 3.56-7.54 7.97v5.2a4.33 4.33 0 0 0 4.2 4.44h6.68c2.3 0 4.2-1.97 4.2-4.43v-5.21c0-4.4-3.37-7.97-7.54-7.97z" />
      <path
        fill={backgroundColor || '#F4F4F4'}
        d="M10.12 16.23a6.65 6.65 0 0 1-2.87-.64c-.38-.2-2.24 1.39-2.55 1.15-.22-.2 1.07-2.2.88-2.4a3.8 3.8 0 0 1-.95-2.45c0-2.41 2.46-4.38 5.49-4.38 3.03 0 5.49 1.97 5.49 4.38 0 2.4-2.5 4.34-5.49 4.34z"
      />
      <path
        fill={fill || '#4A4A4A'}
        d="M9.3 3.2c.13.1.19.26.19.42v.92c0 .03.03.06.06.06.2 0 .4-.03.59-.03.19 0 .38 0 .58.03.03 0 .06-.03.06-.06v-.92c0-.16.07-.32.2-.42.32-.26.51-.65.51-1.08 0-.78-.64-1.43-1.45-1.37-.68.03-1.23.59-1.3 1.24 0 .49.2.91.56 1.2zM6.7 23.68a3.72 3.72 0 0 0 3.42 2.12c1.55 0 2.91-.88 3.43-2.12H6.69zm-5.5-2.81c-.38 0-.68-.38-.68-.86v-5.33c0-.47.3-.86.69-.86.38 0 .68.39.68.86v5.33c-.02.48-.33.86-.68.86zm17.83 0c-.38 0-.68-.38-.68-.86v-5.33c0-.47.3-.86.68-.86.38 0 .69.39.69.86v5.33c-.03.48-.33.86-.69.86z"
      />
      <g fill={fill || '#4A4A4A'} transform="translate(6.52 10.75)">
        <ellipse cx="1.19" cy="1.04" rx="1" ry="1.02" />
        <ellipse cx="5.39" cy="1.04" rx="1" ry="1.02" />
      </g>
    </g>
  </svg>
);
