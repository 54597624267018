import React, { Component } from 'react';
import {
  Box,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextField,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from './colors';
const { bubbleBlue } = colors;

const styles = {
  textField: {
    padding: 0,
    margin: 0
  },
  cssLabel: {
    '&$cssFocused': {
      color: bubbleBlue,
    },
  },
  cssFocused: {
    "&$focused": {
      color: "yellow"
    }
  },
  cssUnderline: {
    '&:after': {
      borderBottom: `2px solid ${bubbleBlue}`,
    },
  },
  fulfilmentActionBox: {
    backgroundColor: "#F2F2F2",
    margin: 10,
    paddingBottom: 0,
    borderRadius: 10,
    padding: 10,
  },
  hideBorder: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
  },
};

const Message = (props) => {
  const { action, onChange, classes } = props;
  const { intentIndex, actionIndex } = props;
  const { value } = action;
  return (
    <TextField
      className={classes.textField}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
        shrink: true,
      }}
      InputProps={{
        classes: {
          root: classes.inputField,
          underline: classes.cssUnderline,
        },
      }}
      label="Response"
      fullWidth
      multiline
      value={value}
      onChange={event => onChange(intentIndex, actionIndex, event.target.value)}
      style={{ marginBottom: 20 }}
    />
  );
};

const StyledMessage = withStyles(styles)(Message);

const MessageVariances = (props) => {
  const { action, onChange, classes } = props;
  const { intentIndex, actionIndex } = props;
  const { value } = action;
  return value.map((_value, index) => (
    <TextField
      className={classes.textField}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
        shrink: true,
      }}
      InputProps={{
        classes: {
          root: classes.inputField,
          underline: classes.cssUnderline,
        },
      }}
      label="Response"
      key={index}
      fullWidth
      multiline
      value={_value}
      onChange={event => onChange(intentIndex, actionIndex, index, event.target.value)}
      style={{ marginBottom: 20 }}
    />
  ));
};

const StyledMessageVariances = withStyles(styles)(MessageVariances);

const CustomizeFulfilmentActions = (props) => {
  const { fulfilmentAction, updateMessage, updateMessageVariant } = props;
  const { intentIndex, actionIndex, classes } = props;
  const { type } = fulfilmentAction;
  return (
    <Box className={classes.fulfilmentActionBox}>
      {type === 'message'
        ? <StyledMessage intentIndex={intentIndex} actionIndex={actionIndex} action={fulfilmentAction} onChange={updateMessage} />
        : <StyledMessageVariances intentIndex={intentIndex} actionIndex={actionIndex} action={fulfilmentAction} onChange={updateMessageVariant} />}
    </Box>
  );
};

const StyledCustomizeFulfilmentActions = withStyles(styles)(CustomizeFulfilmentActions);

const Utterances = (props) => {
  const { utterances } = props;
  if (!utterances) return null;
  return (
    <Typography variant="body1" gutterBottom>
      {utterances[0]}
    </Typography>
  );
};

const FulfilmentActions = (props) => {
  const { fulfilmentActions, intentIndex } = props;
  const { updateMessage, updateMessageVariant } = props;
  if (!fulfilmentActions) return null;
  return fulfilmentActions.map((fulfilmentAction, index) => (<StyledCustomizeFulfilmentActions
    key={index}
    intentIndex={intentIndex}
    actionIndex={index}
    fulfilmentAction={fulfilmentAction}
    updateMessage={updateMessage}
    updateMessageVariant={updateMessageVariant}
  />));
};

const IntentCustomization = (props) => {
  const { intentSet, classes } = props;
  const { /*updateUtterance,*/ updateMessage, updateMessageVariant } = props;
  return intentSet.Intents.map((intent, index) => (
    <ExpansionPanel key={index} style={{ boxShadow: 'none' }} className={classes.hideBorder}>
      <Grid container>
        <Grid item xs={4}>
          <Utterances
            utterances={intent.Utterances}
            intentIndex={index}
          />
          </Grid>
        <Grid item xs={8}>
          <FulfilmentActions
            fulfilmentActions={intent.FulfilmentActions}
            intentIndex={index}
            updateMessage={updateMessage}
            updateMessageVariant={updateMessageVariant}
            />
        </Grid>
      </Grid>
    </ExpansionPanel>
  ));
};

const StyledIntentCustomization = withStyles(styles)(IntentCustomization);

class IntentCustomizationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  render() {
    const { intentSet } = this.state;
    const { addDirtyIntent } = this.props;
    const updateUtterance = (intentIndex, utteranceIndex, value) => {
      const currentState = { ...this.state };
      currentState.intentSet.Intents[intentIndex].Utterances[utteranceIndex] = value;
      this.setState(currentState);
      addDirtyIntent({ ...currentState.intentSet.Intents[intentIndex] });
    };
    const updateMessage = (intentIndex, actionIndex, value) => {
      const currentState = { ...this.state };
      currentState.intentSet.Intents[intentIndex].FulfilmentActions[actionIndex].value = value;
      this.setState(currentState);
      addDirtyIntent({ ...currentState.intentSet.Intents[intentIndex] });
    };
    const updateMessageVariant = (intentIndex, actionIndex, variantIndex, value) => {
      const currentState = { ...this.state };
      currentState.intentSet.Intents[intentIndex].FulfilmentActions[actionIndex].value[variantIndex] = value;
      this.setState(currentState);
      addDirtyIntent({ ...currentState.intentSet.Intents[intentIndex] });
    };
    const nameMappings = {
      "General FAQs": "Customize FAQ responses to questions about things like your opening hours and location.",
      "Smalltalk": "Customize small talk responses to questions about things like Alexa and jokes.",
      "Covid 19": "Customize responses to questions about how your business is responding to COVID19",
    }
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                { intentSet.Name }
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom>
                { nameMappings[intentSet.Name] }
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledIntentCustomization
                intentSet={intentSet}
                updateUtterance={updateUtterance}
                updateMessage={updateMessage}
                updateMessageVariant={updateMessageVariant}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default IntentCustomizationPanel;
