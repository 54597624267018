import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Grid,
  MenuItem,
  MenuList,
  Typography,
  Button,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { withStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';

import colors from './colors';

const {
  primary, white, green, lightGreen, navy
} = colors;
const whitelabel = (process.env.REACT_APP_WHITELABEL === 'true');
const styles = {
  button: {
    backgroundColor: primary,
    color: white,
  },
  buttonIcon: {
    marginLeft: 6,
  },
  description: {
    marginBottom: 30,
    marginTop: 20,
  },
  setWrapper: {
    padding: '20px',
  },
  successBoxContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: '20px',
  },
  successBoxHighlight: {
    display: 'flex',
    backgroundColor: green,
    width: '10px',
  },
  successBoxDescription: {
    display: 'flex',
    flex: 1,
    backgroundColor: lightGreen,
    flexDirection: 'column',
    padding: '10px',
  },
  menuItemContainer: {
    display: 'flex',
    '&:hover': { backgroundColor: lightGreen },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    color: white,
    backgroundColor: (whitelabel) ? navy : primary,
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: '50%',
    marginBottom: 10,
  },
};



class BotResult extends Component {
  constructor(props) {
    super(props);
    const inboundState = this.props.location.state;
    if (!inboundState || !inboundState.bot) return this.props.history.push('/');
    const { bot, createRequest, stepsStore } = inboundState;
    this.state = { bot, createRequest, stepsStore };
    this.onUtteranceClicked = this.onUtteranceClicked.bind(this);
    this.onSaveEmbedClicked = this.onSaveEmbedClicked.bind(this);
  }
  componentDidMount() {
    let { embedSet } = this.state;
    const { bot } = this.state;
    const { Organization, Endpoint, SBRegion } = bot;
    const { Address } = Endpoint;
    if (!embedSet) {
      const botty = window.ServisBot.init({
        organization: Organization,
        endpoint: Address,
        sbRegion: SBRegion,
        alwaysOpen: true,
        container: '#messengerEmbed',
      });
      this.setState({ botty });
    }
    embedSet = true;
    this.setState({ embedSet });
  }
  onUtteranceClicked(intent) {
    const { SampleUtterance } = intent;
    this.state.botty.sendMessage(SampleUtterance);
  }
  async onSaveEmbedClicked() {
    const saveEmbedResult = await fetch(`${process.env.PUBLIC_URL}/SaveEmbedConfiguration`, {
      method: 'post',
      accept: 'application/json',
      body: JSON.stringify(this.state.bot),
      headers: { 'Content-Type': 'application/json' },
    });
    if (saveEmbedResult.status !== 200) {
      this.setState({ saved: true, saveEmbedResult: 'Your bot embed configuration failed to save' });
      return;
    }
    this.setState({ saved: true, saveEmbedResult: 'Your bot embed configuration is saved!' });
  }
  iteratePayloadsForUtterances(entry) {
    const { createRequest, stepsStore } = this.state;
    let headings = createRequest[entry].map((intentSet) => {
      const intentSetName = intentSet.Name;
      const intentSetDefinition = stepsStore[entry].find(i => i.DisplayName === intentSetName);
      const Intents = [];
      intentSetDefinition.Intents.forEach((i) => {
        const intentSetAliases = intentSet.Intents.map(i => i.Alias);
        if (intentSet.Intents && intentSet.Intents.length > 0 && (!intentSet.Intents.includes(i.Alias) && !intentSetAliases.includes(i.Alias))) {
          return false;
        }
        Intents.push({ Alias: i.Alias, SampleUtterance: i.Utterances[0] });
      });
      return { Alias: intentSetName, Intents };
    });
    headings = headings.length && headings.flat();
    headings = headings.length && headings.filter(h => h);
    return headings || [];
  }
  render() {
    const { classes } = this.props;
    const { bot } = this.state;
    const { Organization, Endpoint } = bot;
    const { Address } = Endpoint;
    const businessintentSetHeadings = this.iteratePayloadsForUtterances('BusinessIntentSets');
    const intentSetHeadings = this.iteratePayloadsForUtterances('IntentSets');

    const utteranceHeadings = businessintentSetHeadings.concat(intentSetHeadings);
    const utterances = () => utteranceHeadings.map((heading, idx) => {
      const intentsSubset = heading.Intents.slice(0, 5); // only take the first 5 of every set
      const utterancesMenuItems = () => intentsSubset.map((intent, idx) => (
        <MenuItem onClick={() => this.onUtteranceClicked(intent)} className={classes.menuItemContainer} key={ `MenuItem${intent.Alias}${idx}`}>
          <Box className={classes.icon}>
            <ChatBubbleOutlineIcon />
          </Box>
          <Box>
            <Typography variant="subtitle2">{intent.SampleUtterance}</Typography>
            <Typography variant="caption">{intent.Alias}</Typography>
          </Box>
        </MenuItem>
      ));
      return (
        <Box key={ `BoxHeading${idx}` }>
          <Typography variant="h6" gutterBottom>Try a {heading.Alias} related utterance:</Typography>
          <MenuList>{utterancesMenuItems()}</MenuList>
        </Box>
      );
    });
    const src = `
<script 
  src="https://servisbotcdn.com/messenger/latest/bundle-messenger.js">
</script>
ServisBot.init({
  "organization": "${Organization}",
  "endpoint": "${Address}",
  "sbRegion": "us-1"
});
`;
    return (
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={6}>
          <Box className={classes.successBoxContainer}>
            <Box className={classes.successBoxHighlight} />
            <Box className={classes.successBoxDescription}>
              <Typography variant="h6">
                Your bot has been created!
              </Typography>
              <Typography variant="body1">
                Test it out by selecting a an option below.
              </Typography>
            </Box>
          </Box>
          { utterances() }
        </Grid>
        <Grid item xs={6}>
          <Box id="messengerEmbed" />
        </Grid>
        {whitelabel &&
        <Grid container justify="center">
          <Grid item>
            <Button className={classes.button} disableElevation variant="contained" onClick={this.onSaveEmbedClicked}>
              Save Configuration <SaveIcon className={classes.buttonIcon} />
            </Button>
          </Grid>
        </Grid>
        }
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.saved === true}
          autoHideDuration={6000}
          message={this.state.saveEmbedResult}
          onClose={() => { this.setState({ saved: false }); }}
          style={{ zIndex: '9999999999' }} // servisbot embed otherwise bleeds ontop of these
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(BotResult));
