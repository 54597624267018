import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Icon from '@material-ui/core/Icon';
import GridCardHeaderStyle from './GridCardHeader.style';

function GridCardHeader(props) {
  const {
    title, canBeEnabled, enabled, onSwitch, icon, classes,
  } = props;
  const { switchBase, checked, track } = classes;

  return (
    <Grid container direction="row" alignItems="center" spacing={0}>
      <Grid>
        {canBeEnabled &&
        <Switch
          name="enabled"
          onChange={onSwitch}
          checked={enabled}
          classes={{ switchBase, checked, track }} />
        }
        {icon &&
          <div className={classes.icon}>
            <Icon>{icon}</Icon>
          </div>
        }
      </Grid>
      <Grid item>
        <Typography gutterBottom variant="h6" component="h4"> { title } </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(GridCardHeaderStyle)(GridCardHeader);
