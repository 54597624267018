const bubbleBlue = '#03a4e8';
const green = '#76bc21';
const navy = '#0D233B';
const whitelabel = (process.env.REACT_APP_WHITELABEL === 'true');
const colors = {
  primary: (whitelabel) ? green : '#0392CF',
  warning: '#FF9800',
  danger: '#f50057',
  success: '#4CAF50',
  info: '#999999',
  gray: '#999999',
  darkGray: '#444444',
  white: '#FFFFFF',
  black: '#131313',
  bubbleBlue,
  bubbleBlueOutline: '#0392cf',
  lightGray: '#f2f2f2',
  bubbleGray: '#d8d8d8',
  materialPrimary: '#009688',
  lightPink: '#ff4081',
  paperBackground: '#f8f8f8',
  paperText: '#464646',
  green,
  lightGreen: '#EEF7E4',
  navy,
  loadingBlue: '#CDE9F5'
};

export default colors;
